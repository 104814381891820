export const WORK_EXPERIENCE = [
  {
    rightContent: "Oct 2019 - Present",
    caption: "Solera Health",
    title: "Senior Software Engineer",
    icon: "laptop",
    summary: [
      {
        description: `Designed and developed RESTful APIs with unit tests and integration tests`,
      },
      {
        description:
          "Built an engagement engine to automate sending marketing and engagement notifications through emails and sms using Azure function apps and Service bus",
      },
      {
        description:
          "Worked on multiple React and Web component UI applications using Redux as state management tool",
      },
      {
        description:
          "Integrating with third party apps using OIDC and SAML protocols with Auth0 infrastructure",
      },
      {
        description:
          "Built a simple tracking system on the front-end to track and store custom user actions of the app",
      },
      {
        description:
          "Developed data storage strategy on MongoDB, ETL process to migrate data from MongoDB to SQL using Azure databricks",
      },
      {
        description:
          "Developed and maintained CI/CD pipelines for multiple repositories, actively participated in production releases.",
      },
    ],
  },
  {
    rightContent: "Aug 2018 – Oct 2019",
    caption: "Trizetto - A Cognizant Company",
    title: "Principal Software Engineer",
    icon: "laptop",
    summary: [
      {
        description:
          "Working as part of Rapid Development Team on next Gen product - an Azure cloud solution",
      },
      {
        description:
          "Developed multiple Angular SPAs to load in different Iframes, backend was built using .NET Core with multiple microservices",
      },
      {
        description:
          "Developed coding templates using Angular Schematics to promote rapid development",
      },
      {
        description:
          "Won multiple Hackathons on bringing innovative solutions to our products",
      },
    ],
  },
  {
    rightContent: "April 2016 – August 2018",
    caption: "Trizetto - A Cognizant Company",
    title: "Senior Software Engineer",
    icon: "laptop",
    summary: [
      {
        description:
          "Led and coordinated the front-end team to move the front-end stack from AngularJS to Angular 2, TypeScript, Webpack and RxJS",
      },
      {
        description:
          "Worked on .NET restful APIs and security enhancements on the application",
      },
      {
        description:
          "Multiple POCs to evaluate the third-party libs to be used in app",
      },
      {
        description: "Led code reviews across multiple teams on the UI",
      },
      {
        description: "Developed coding and validation patterns across the UI",
      },
      {
        description:
          "Identified and developed common UI components, utilities and services into NPM libraries to be reused",
      },
    ],
  },
  {
    rightContent: "March 2015 – April 2016",
    caption: "Trizetto",
    title: "Software Engineer",
    icon: "laptop",
    summary: [
      {
        description:
          "Development and customization of the QNXT application using AngularJS MVC framework, Bootstrap, JQuery, Kendo",
      },
      {
        description:
          "Developed Javascript UI framework to be used for an internal portfolio application",
      },
      {
        description:
          "Developed an internal Angular Electron application to automate uploading NPM packages to a private Artifactory Server",
      },
      {
        description:
          "Maintaining the existing applications and maintaining Unit Test Coverage of 90%",
      },
    ],
  },
  {
    rightContent: "December 2014 – March 2015",
    caption: "Eigen Solutions",
    title: "Software Consultant",
    icon: "laptop",
    summary: [
      {
        description:
          "Developed the UI user interface pages using HTML5, CSS3, JavaScript – angular.js, JQuery, Ajax",
      },
      {
        description:
          "Wrote SQL queries, stored procedures and triggers to interact with SQL Server database",
      },
      {
        description: "Helped with FitNesse test and worked with QA team",
      },
      {
        description:
          "Maintaining the existing applications and maintaining Unit Test Coverage of 90%",
      },
    ],
  },
];

export const EDUCATION = [
  {
    rightContent: "Aug 2012 - May 2014",
    title: "Purdue University, West Lafayette, IN, USA",
    caption: "Master of Science in Electrical and Computer Engineering",
    icon: "school",
    summary: [],
  },
  {
    rightContent: "Aug 2008 – Jun 2012",
    title: "MS Ramaiah Institute of Technology, Bangalore, KA, India",
    icon: "school",
    caption: "Bachelor of Electronics & Communication Engineering",
    summary: [],
  },
];

export const PROJECT = [
  {
    rightContent: "React, MongoDB, Express, NodeJS",
    title: "Personal Website - ronakvpanchal.com",
    caption: "Built using MERN stack",
    icon: "project",
    summary: [
      {
        description:
          "Created personal website using MERN stack, hosting on digitalocean droplet",
      },
      {
        description: "Configured domain, routing api traffic through a proxy",
      },
      {
        description:
          "Network settings done using nginx and running app on the server using pm2",
      },
    ],
  },
  {
    rightContent: "Javascript Game",
    title: "Mine Sweeper",
    icon: "project",
    caption: "A simple version of sweeping mines",
    summary: [
      {
        description:
          "Implemented using React smart and presentational components architecture",
      },
      {
        description:
          "Developed keeping re-usability as one of the major development pointer",
      },
    ],
  },
  {
    rightContent: "Electron App with Angular",
    title: "NPM library Manager",
    icon: "project",
    caption: "Built with Angular 7, manages npm libraries",
    summary: [
      {
        description:
          "Install npm libraries version specific locally and uploads it to built an Artifactory server",
      },
      {
        description:
          "Using comand line tools underhood to manage npm commands and write a lock file",
      },
    ],
  },
];
