import React, { useState, useEffect, useContext, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import { SocketContext } from "../utils/socketContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  cardContainer: {
    maxWidth: 250,
    minWidth: 200,
  },
  messageTimeContainer: {
    maxWidth: 250,
    minWidth: 200,
  },
  cardWrapper: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    margin: "10px",
  },
  otherCardWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
  },
  titleContainer: {
    margin: "20px",
  },
}));

const OtherMessageCard = withStyles((theme) => ({
  root: {
    backgroundColor: grey[500],
  },
}))(Card);

const MyMessageCard = withStyles((theme) => ({
  root: {
    backgroundColor: green[500],
  },
}))(Card);

const socket = function SocketTest() {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [allMessages, setAllMessages] = useState("");
  const socket = useContext(SocketContext);
  const user = useSelector((state) => state.user);
  const userRef = useRef(user);

  // TODO: connect to server only if the user is logged in

  useEffect(() => {
    console.log("use effect: ", user);
    userRef.current = user;
    if (user && user.username) {
      connectToServer();
    }
    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, [user]);

  const displayUserList = (userList) => {
    console.log("from the backend server: ", userList);
    setUserList(userList);
  };

  const displayAllMessages = (allMsg) => {
    console.log("messages server: ", allMsg);
    allMsg.sort((a, b) => b.timestamp - a.timestamp);
    setAllMessages(allMsg);
  };

  const connectToServer = () => {
    console.log(userRef.current);
    socket.on("sendUserList", displayUserList);
    socket.on("allMessages", displayAllMessages);
    socket.emit("userConnectToServer", userRef.current);
  };

  const sendMessage = () => {
    socket.emit("sendMessage", {
      username: userRef.current.username,
      timestamp: moment(),
      message: userMessage,
    });
    setUserMessage("");
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography component="h3" variant="h3" color="textSecondary">
          Socket Testing
        </Typography>
      </div>
      <div>
        <Typography color="textSecondary">List of Users</Typography>
      </div>
      <div>
        <div>
          {userList.length > 0 && (
            <div>
              {userList.map((id) => (
                <Typography key={id.username} color="textSecondary">
                  {id.username}
                </Typography>
              ))}
            </div>
          )}
        </div>
        <div>
          <div>
            {allMessages.length > 0 && (
              <div>
                {allMessages.map((msg, index) => (
                  <div key={index}>
                    {user.username === msg.username && (
                      <div className={classes.cardWrapper}>
                        <MyMessageCard
                          className={classes.cardContainer}
                          variant="outlined"
                        >
                          <CardContent>
                            <Typography color="textSecondary" align="right">
                              You
                            </Typography>

                            <Typography align="left" variant="subtitle2">
                              {msg.message}
                            </Typography>
                          </CardContent>
                        </MyMessageCard>
                        <div className={classes.messageTimeContainer}>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                            align="right"
                            component="p"
                          >
                            {` ${moment(msg.timestamp).format("LTS")}`}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {user.username !== msg.username && (
                      <div className={classes.otherCardWrapper}>
                        <OtherMessageCard
                          className={classes.cardContainer}
                          variant="outlined"
                        >
                          <CardContent>
                            <Typography color="textSecondary" align="right">
                              Other User
                            </Typography>

                            <Typography align="left" variant="subtitle2">
                              {msg.message}
                            </Typography>
                          </CardContent>
                        </OtherMessageCard>
                        <div className={classes.messageTimeContainer}>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                            align="left"
                            component="p"
                          >
                            {` ${moment(msg.timestamp).format("LTS")}`}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <TextField
            style={{ width: "100%" }}
            label="Message here"
            variant="outlined"
            multiline
            rows={3}
            rowsMax={5}
            value={userMessage}
            onChange={(event) => setUserMessage(event.target.value)}
          />
          <Button color="secondary" size="small" onClick={sendMessage}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default socket;
