import axios from "axios";
const baseUrl = `https://hacker-news.firebaseio.com/v0`;

export default {
  getTopStories: async () => {
    let resultNews = await axios({
      method: "get",
      url: baseUrl + "/topstories.json"
    });

    return resultNews.data;
  },

  getNewStories: async () => {
    let resultNews = await axios({
      method: "get",
      url: baseUrl + "/newstories.json"
    });

    return resultNews.data;
  },

  getBestStories: async () => {
    let resultNews = await axios({
      method: "get",
      url: baseUrl + "/beststories.json"
    });

    return resultNews.data;
  },

  getStory: async storyId => {
    let result = await axios({
      method: "get",
      url: baseUrl + `/item/${storyId}.json`
    });

    return result.data;
  }
};
