import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { theme } from "./theme";
import Home from "./components/Home";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import SignInSide from "./components/SignIn";
import SocketTest from "./chat/chatContainer";
import HackerNewsScreen from "./hackerNews/HackerNewsScreen";
import FitnessBorad from "./fitness/FitnessBoard";
import PushUps from "./fitness/pushUps/PushUps";
import Squats from "./fitness/squats/Squats";
import Todos from "./todo/Todos";
import MineSweeper from "./minesweeper/SweeperBox";
import { useWindowResize } from "./utils/windowResizeHook";
import { SocketContext, socket } from "./utils/socketContext";

function App() {
  document.title = "Ronak Panchal - Portfolio";
  const { height } = useWindowResize();

  return (
    <Provider store={store.store}>
      <SocketContext.Provider value={socket}>
        <Router>
          <ThemeProvider theme={theme}>
            <div className="App">
              <AppHeader></AppHeader>
              <div className="bodyContainer" style={{ height: height }}>
                <Switch>
                  <Route path="/message">
                    <SocketTest></SocketTest>
                  </Route>
                  <Route path="/todo">
                    <Todos></Todos>
                  </Route>
                  <Route path="/pushups">
                    <PushUps></PushUps>
                  </Route>
                  <Route path="/squats">
                    <Squats></Squats>
                  </Route>
                  <Route path="/minesweeper">
                    <MineSweeper></MineSweeper>
                  </Route>
                  <Route path="/signIn">
                    <SignInSide></SignInSide>
                  </Route>
                  <Route path="/fitness">
                    <FitnessBorad></FitnessBorad>
                  </Route>
                  <Route path="/hackernews">
                    <HackerNewsScreen></HackerNewsScreen>
                  </Route>
                  <Route exact path="/">
                    <Home></Home>
                  </Route>
                </Switch>
              </div>
              <AppFooter />
            </div>
          </ThemeProvider>
        </Router>
      </SocketContext.Provider>
    </Provider>
  );
}

export default App;
