import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import { Portfolio } from "../portfolio/Portfolio";
import Fab from "@material-ui/core/Fab";
import MessageIcon from "@material-ui/icons/Message";
import { withRouter } from "react-router-dom";

const styles = (theme) => {
  console.log(theme);
  return {
    rootContainer: {
      width: "100%",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflow: "auto",
    },
    titleContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundImage:
        "url(https://images.unsplash.com/photo-1497091071254-cc9b2ba7c48a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2253&q=80)",
      backgroundSize: "cover",
      minHeight: "300px",
      justifyContent: "center",
    },
    shortDescription: {
      marginTop: 20,
      color: "#fff",
    },
    imgText: {
      margin: "0 auto",
      display: "flex",
      color: "#fff",
    },
    avatar: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      margin: "0 15px",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      width: "100%",
      margin: "20px",
    },
    userGreetingContainer: {
      width: "100%",
      margin: "20px",
    },
  };
};

class Home extends Component {
  goToMessage = () => {
    const { history } = this.props;
    console.log(history);
    if (history) history.push("/message");
  };
  render() {
    const { classes, user } = this.props;
    return (
      <div className={classes.rootContainer}>
        {!user.loggedIn && (
          <div className={classes.titleContainer}>
            <div className={classes.imgText}>
              <Avatar
                alt="Ronak Panchal Image"
                src="LinkedInProfile.jpg"
                className={classes.avatar}
              />
              <Typography align="left" component="h3" variant="h3">
                I'm <b>ronak</b>,<br></br>creator innovator developer
              </Typography>
            </div>

            <Typography
              className={classes.shortDescription}
              component="h5"
              variant="h5"
            >
              Passionate full-stack engineer focused on continuous learning
            </Typography>
          </div>
        )}
        {user.loggedIn && (
          <div className={classes.userGreetingContainer}>
            <Typography align="center" component="h4" variant="h4">
              <b>Welcome to Goa</b>
              <br></br>
              {user.username}
            </Typography>

            <div className={classes.buttonContainer}>
              <Fab variant="extended" onClick={this.goToMessage}>
                <MessageIcon className={classes.extendedIcon} />
                Message
              </Fab>
            </div>
          </div>
        )}

        {!user.loggedIn && <Portfolio></Portfolio>}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Home)));
