import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import moment from "moment";
import urlDetailService from "../services/urlDetailService";
import { CardMedia, CardHeader, CardActionArea } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 320,
      maxWidth: 600,
      margin: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: "auto",
      flex: "0 1 30%",
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
    },
    cardContent: {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: "rgba(0,0,0, 0.12)",
    },
    cardMedia: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    detailContainer: {
      fontSize: 14,
      display: "flex",
      marginTop: 20,
      justifyContent: "space-between",
      flexDirection: "column-reverse",
      alignItems: "baseline",
    },
    pos: {
      fontSize: "inherit",
    },
  };
});

export function HackerNewsCard({ story }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [urlInfo, setUrlInfo] = useState(null);

  useEffect(() => {
    if (story != null && story.url != null) {
      const maxLength = story.url.length > 30 ? 30 : story.url.length;
      const displayUrl = story.url.substring(0, maxLength) + "...";
      story.displayUrl = displayUrl;
      fetchUrlDetails(story.url);
    }

    return () => {};
  }, [story]);

  const fetchUrlDetails = async (url) => {
    if (!user.loggedIn || urlInfo != null) return;
    const urlDetails = await urlDetailService.fetchUrlInfo([url]);
    if (urlDetails && urlDetails.urlInfo != null) {
      if (urlDetails.urlInfo.description != null) {
        const maxLength =
          urlDetails.urlInfo.description.length > 80
            ? 80
            : urlDetails.urlInfo.description.length;
        const description =
          urlDetails.urlInfo.description.substring(0, maxLength) + "...";
        urlDetails.urlInfo.description = description;
      }
      setUrlInfo(urlDetails.urlInfo);
    }
  };

  const openStoryLink = () => {
    window.open(story.url);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={openStoryLink}>
        {urlInfo != null && urlInfo.title != null && urlInfo.title !== "" && (
          <CardHeader
            title={urlInfo.title}
            subheader={`${urlInfo.description}`}
          />
        )}

        {urlInfo == null && (
          <CardHeader title={story.title} subheader={story.by} />
        )}

        {urlInfo != null && urlInfo.title == null && (
          <CardHeader title={story.title} subheader={story.by} />
        )}

        {urlInfo != null && urlInfo.title === "" && (
          <CardHeader title={story.title} subheader={story.by} />
        )}

        {urlInfo != null && urlInfo.image != null && (
          <CardMedia
            className={classes.cardMedia}
            image={urlInfo.image}
          ></CardMedia>
        )}
      </CardActionArea>
      <CardContent className={classes.cardContent}>
        <div className={classes.detailContainer}>
          {story.displayUrl != null && (
            <Typography className={classes.pos} color="textSecondary">
              URL: {story.displayUrl}
            </Typography>
          )}
          <Typography className={classes.pos} color="textSecondary">
            Score: {story.score}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Date: {moment.unix(story.time).format("LLL")}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          onClick={openStoryLink}
        >
          Read Story
        </Button>
      </CardActions>
    </Card>
  );
}
