import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../reducers/user";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";

const ITEM_HEIGHT = 48;

const options = [
  { display: "Home", value: "/" },
  { display: "Hacker News", value: "/hackernews" },
  { display: "Todo", value: "/todo" },
  { display: "Push Ups", value: "/pushups" },
  { display: "Squats", value: "/squats" },
  { display: "Fitness", value: "/fitness" },
  { display: "Mine Sweeper", value: "/minesweeper" },
];

const allRoutes = [...options, { display: "Sign In", value: "/signin" }];

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [currentRouteSelected, handleCurrentRouteChanged] = useState("/");
  const user = useSelector((state) => state.user);
  const [pageTitle, setPageTitle] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    handleCurrentRouteChanged(location.pathname.toLocaleLowerCase());
    const title = allRoutes.find((opt) => {
      return opt.value === location.pathname.toLocaleLowerCase();
    });
    if (title) {
      setPageTitle(title.display);
    }
  }, [location]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logIn = () => {
    history.push("signin");
  };

  const logOut = async () => {
    await dispatch(logoutUser());
    dispatch({ type: "USER_LOGGED_OUT" });
    history.push("/");
  };

  const handleClose = (event) => {
    if (event.value == null) {
      history.push(currentRouteSelected);
    } else {
      history.push(`${event.value}`);
    }
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                selected={
                  option.value.toLocaleLowerCase() === currentRouteSelected
                }
                onClick={() => {
                  handleClose(option);
                }}
              >
                {option.display}
              </MenuItem>
            ))}
          </Menu>
          <Typography variant="h6" className={classes.title}>
            {pageTitle}
          </Typography>
          {user && !user.loggedIn && (
            <Button onClick={logIn} color="inherit">
              LOGIN
            </Button>
          )}
          {user && user.loggedIn && (
            <Button onClick={logOut} color="inherit">
              LOGOUT
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
