import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import SweeperCell from "./SweeperCell";
import Switch from "@material-ui/core/Switch";
import Notifications from "../components/notifications/notification";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  fullBody: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
    minHeight: "60px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardsContainer: {
    overflow: "auto",
    display: "flex",
    flexGrow: 1,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  addContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    margin: "10px 10px",
    padding: "20px",
    width: "100%",
  },
  sweeperRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  gameRender: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  gameHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  gameActionControls: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px",
  },
  createGameBtn: {
    marginBottom: "20px",
    marginTop: "10px",
  },
  counterText: {
    margin: "10px",
    maxWidth: "80px",
  },
  sweeperCol: { display: "flex", flexDirection: "row" },
}));

const SweeperBoxComponent = function () {
  const classes = useStyles();
  const [sweeperBoardState, setSweeperBoardState] = useState([]);
  const [reloadBoard, setReloadBoard] = useState(0);
  const [showAllCells, setShowAllCells] = useState(false);
  const [flagMode, setFlagMode] = useState(false);
  const [gameLostNotification, setGameLostNotification] = useState(false);
  const [gameWonNotification, setGameWonNotification] = useState(false);
  const [displayedRows, setRows] = useState(10);
  const [displayedCols, setCols] = useState(10);
  const [displayedMineNumber, setMineNumber] = useState(16);
  const [actualMineNumber, setActualMineNumber] = useState(0);
  const [render, setRender] = useState(false);

  useEffect(() => {
    setRender(false);
    const tempSweeperBoardState = [];
    const rows = displayedRows * 1;
    const cols = displayedCols * 1;
    const mineNumber = displayedMineNumber * 1;

    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      const tempSweeperRowState = [];
      for (let colIndex = 0; colIndex < cols; colIndex++) {
        const cellState = {
          isMine: false,
          posX: rowIndex,
          posY: colIndex,
          mineCount: 0,
          isFlagged: false,
          isClicked: false,
        };
        tempSweeperRowState.push(cellState);
      }
      tempSweeperBoardState.push(tempSweeperRowState);
    }

    if (tempSweeperBoardState.length === 0) {
      return;
    }

    for (let index = 0; index < mineNumber; index++) {
      const randomX = Math.floor(Math.random() * rows);
      const randomY = Math.floor(Math.random() * cols);
      if (tempSweeperBoardState[randomX][randomY]) {
        tempSweeperBoardState[randomX][randomY].isMine = true;
      }
    }

    let tempActualMineNumber = 0;

    // calculate mine neighbors
    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      for (let colIndex = 0; colIndex < cols; colIndex++) {
        if (tempSweeperBoardState[rowIndex][colIndex].isMine) {
          tempActualMineNumber++;
        }

        let mineCount = 0;

        if (
          rowIndex - 1 >= 0 &&
          colIndex - 1 >= 0 &&
          tempSweeperBoardState[rowIndex - 1][colIndex - 1].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex - 1 >= 0 &&
          colIndex >= 0 &&
          tempSweeperBoardState[rowIndex - 1][colIndex].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex - 1 >= 0 &&
          colIndex + 1 < cols &&
          tempSweeperBoardState[rowIndex - 1][colIndex + 1].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex >= 0 &&
          colIndex - 1 >= 0 &&
          tempSweeperBoardState[rowIndex][colIndex - 1].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex >= 0 &&
          colIndex >= 0 &&
          tempSweeperBoardState[rowIndex][colIndex].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex >= 0 &&
          colIndex + 1 < cols &&
          tempSweeperBoardState[rowIndex][colIndex + 1].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex + 1 < rows &&
          colIndex - 1 >= 0 &&
          tempSweeperBoardState[rowIndex + 1][colIndex - 1].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex + 1 < rows &&
          colIndex >= 0 &&
          tempSweeperBoardState[rowIndex + 1][colIndex].isMine
        ) {
          mineCount++;
        }

        if (
          rowIndex + 1 < rows &&
          colIndex + 1 < cols &&
          tempSweeperBoardState[rowIndex + 1][colIndex + 1].isMine
        ) {
          mineCount++;
        }

        tempSweeperBoardState[rowIndex][colIndex].mineCount = mineCount;
      }
    }
    setActualMineNumber(tempActualMineNumber);
    setSweeperBoardState(tempSweeperBoardState);
    setRender(true);

    setTimeout(() => {}, 2000);
  }, [reloadBoard]);

  const cellClicked = (cell) => {
    if (cell.isMine && !flagMode) {
      gameLost();
      return;
    }

    if (cell.isClicked) {
      // do nothing
      return;
    }

    if (flagMode) {
      let temp = sweeperBoardState;
      temp[cell.posX][cell.posY].isFlagged = !temp[cell.posX][cell.posY]
        .isFlagged;
      reEvaluateArray(temp);
      return;
    }

    if (cell.mineCount === 0) {
      const temp = openEmptyCell(cell, sweeperBoardState);
      reEvaluateArray(temp);
    }

    if (cell.mineCount > 0) {
      let temp = sweeperBoardState;
      temp[cell.posX][cell.posY].isClicked = true;
      reEvaluateArray(temp);
    }
  };

  const openEmptyCell = (targetCell, data) => {
    const neighbors = getNeighbours(targetCell, sweeperBoardState);

    for (const cell of neighbors) {
      if (cell.isMine || cell.isFlagged || cell.isClicked) {
        continue;
      }
      if (cell.mineCount > 0) {
        // reveal it to the user
        data[cell.posX][cell.posY].isClicked = true;
      }
      if (cell.mineCount === 0) {
        data[cell.posX][cell.posY].isClicked = true;
        const resData = openEmptyCell(cell, data);
        data = [...resData];
      }
    }

    return data;
  };

  const getNeighbours = (cell) => {
    const neighbors = [];
    const rows = displayedRows * 1;
    const cols = displayedCols * 1;
    const rowIndex = cell.posX;
    const colIndex = cell.posY;
    if (rowIndex - 1 >= 0 && colIndex - 1 >= 0) {
      neighbors.push(sweeperBoardState[rowIndex - 1][colIndex - 1]);
    }

    if (rowIndex - 1 >= 0 && colIndex >= 0) {
      neighbors.push(sweeperBoardState[rowIndex - 1][colIndex]);
    }

    if (rowIndex - 1 >= 0 && colIndex + 1 < cols) {
      neighbors.push(sweeperBoardState[rowIndex - 1][colIndex + 1]);
    }

    if (rowIndex >= 0 && colIndex - 1 >= 0) {
      neighbors.push(sweeperBoardState[rowIndex][colIndex - 1]);
    }

    if (rowIndex >= 0 && colIndex >= 0) {
      neighbors.push(sweeperBoardState[rowIndex][colIndex]);
    }

    if (rowIndex >= 0 && colIndex + 1 < cols) {
      neighbors.push(sweeperBoardState[rowIndex][colIndex + 1]);
    }

    if (rowIndex + 1 < rows && colIndex - 1 >= 0) {
      neighbors.push(sweeperBoardState[rowIndex + 1][colIndex - 1]);
    }

    if (rowIndex + 1 < rows && colIndex >= 0) {
      neighbors.push(sweeperBoardState[rowIndex + 1][colIndex]);
    }

    if (rowIndex + 1 < rows && colIndex + 1 < cols) {
      neighbors.push(sweeperBoardState[rowIndex + 1][colIndex + 1]);
    }

    return neighbors;
  };

  const startAnotherGame = () => {
    // reset all the flags
    setRender(false);
    setGameLostNotification(false);
    setShowAllCells(false);
    setActualMineNumber(0);
    setReloadBoard(reloadBoard + 1);
  };

  const gameLost = () => {
    setGameLostNotification(true);
    setShowAllCells(true);
  };

  const gameWon = () => {
    setGameWonNotification(true);
  };

  const reEvaluateArray = (data) => {
    const rows = displayedRows * 1;
    const cols = displayedCols * 1;

    const tempSweeperBoardState = [];
    let tempActualMineNumber = 0;
    let flaggedCellCount = 0;
    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      const tempSweeperRowState = [];
      for (let colIndex = 0; colIndex < cols; colIndex++) {
        const oldVal = data[rowIndex][colIndex];
        const cellState = {
          isMine: oldVal.isMine,
          posX: rowIndex,
          posY: colIndex,
          mineCount: oldVal.mineCount,
          isFlagged: oldVal.isFlagged,
          isClicked: oldVal.isClicked,
        };

        if (cellState.isMine) {
          tempActualMineNumber++;
        }

        if (cellState.isFlagged) {
          flaggedCellCount++;
        }
        tempSweeperRowState.push(cellState);
      }
      tempSweeperBoardState.push(tempSweeperRowState);
    }
    setActualMineNumber(tempActualMineNumber - flaggedCellCount);
    setSweeperBoardState(tempSweeperBoardState);

    if (tempActualMineNumber === flaggedCellCount) {
      gameWon();
    }
  };

  return (
    <Grid container component="main" direction="row" className={classes.root}>
      <CssBaseline />
      <Grid
        className={classes.fullBody}
        item
        xs={12}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <div className={classes.headerContainer}>
            <Avatar className={classes.avatar}>
              <FitnessCenterIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Mine Sweeper
            </Typography>
          </div>
          <div className={classes.cardsContainer}>
            <Card className={classes.addContainer}>
              {sweeperBoardState.length !== 0 && (
                <CardContent>
                  <div className={classes.gameActionControls}>
                    <form noValidate>
                      <Typography style={{ marginBottom: "5px" }}>
                        Game Setting
                      </Typography>
                      <TextField
                        className={classes.counterText}
                        label="Rows"
                        type="number"
                        value={displayedRows}
                        onChange={(e) => {
                          setRender(false);
                          setRows(e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />

                      <TextField
                        className={classes.counterText}
                        label="Columns"
                        type="number"
                        value={displayedCols}
                        onChange={(e) => {
                          setRender(false);
                          setCols(e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />

                      <TextField
                        className={classes.counterText}
                        label="Mines"
                        type="number"
                        value={displayedMineNumber}
                        onChange={(e) => {
                          setRender(false);
                          setMineNumber(e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />

                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          className={classes.createGameBtn}
                          startIcon={<ChildCareIcon />}
                          onClick={startAnotherGame}
                        >
                          New Game
                        </Button>
                      </div>
                    </form>
                  </div>
                  {render && (
                    <div className={classes.gameRender}>
                      <div>
                        {gameLostNotification && (
                          <Notifications
                            type="error"
                            msg="Oops.. that was a mine! GAME OVER"
                            show={gameLostNotification}
                            onClose={() => {
                              setGameLostNotification(false);
                            }}
                          ></Notifications>
                        )}

                        {gameWonNotification && (
                          <Notifications
                            type="success"
                            msg="Congratulations.. You WON!"
                            show={gameLostNotification}
                            onClose={() => {
                              setGameWonNotification(false);
                            }}
                          ></Notifications>
                        )}
                      </div>
                      <div className={classes.gameHeader}>
                        <Typography style={{ marginRight: "20px" }}>
                          Mines left: {actualMineNumber}
                        </Typography>

                        <FormGroup row>
                          <FormControlLabel
                            value="start"
                            control={
                              <Switch
                                checked={flagMode}
                                onChange={() => {
                                  setFlagMode(!flagMode);
                                }}
                                name="checkedA"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            }
                            label="Flag Mines"
                          />
                        </FormGroup>
                      </div>
                      <div className={classes.sweeperRow}>
                        {[...Array(displayedRows * 1)].map(
                          (valRow, rowIndex) => {
                            return (
                              <div
                                key={rowIndex}
                                className={classes.sweeperCol}
                              >
                                {[...Array(displayedCols * 1)].map(
                                  (valCol, colIndex) => {
                                    return (
                                      <SweeperCell
                                        key={`${rowIndex}_${colIndex}`}
                                        cellClicked={cellClicked}
                                        cellStateParent={
                                          sweeperBoardState[rowIndex][colIndex]
                                        }
                                        revealed={showAllCells}
                                      ></SweeperCell>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </CardContent>
              )}
            </Card>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default SweeperBoxComponent;
