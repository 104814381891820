import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchRounded from "@material-ui/icons/SearchRounded";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160
  }
}));

export function HackerNewsActionBar({
  sortOptionChanged,
  searchOptionChanged
}) {
  const classes = useStyles();
  const [sortOption, setSortOption] = React.useState("none");
  const [searchText, setSearchText] = React.useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    return () => {};
  });

  const handleSearchChange = event => {
    setSearchText(event.target.value);
    searchOptionChanged(event.target.value);
  };

  const handleSortChange = event => {
    setSortOption(event.target.value);
    sortOptionChanged(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="input-with-icon-adornment">
          Search Stories
        </InputLabel>
        <Input
          value={searchText}
          id="input-with-icon-adornment"
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Sort By</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={sortOption}
          onChange={handleSortChange}
        >
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"score"}>Score (Desc)</MenuItem>
          <MenuItem value={"date"}>Date (Asc)</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
