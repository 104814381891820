import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export let theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      // light: "#757ce8",
      // main: "#353538",
      main: "#03a9f4",
      // dark: "#002884",
      // contrastText: "#fff",
    },
    secondary: {
      // light: "#ff7961",
      // main: "#f44336",
      main: "#42a5f5",
      // dark: "#ba000d",
      // contrastText: "#000",
    },
  },
});

theme = responsiveFontSizes(theme);
