import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30px",
    height: "30px",
    margin: "1px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cells: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.primary.main,
  },
  flaggedCells: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.error.main,
  },
  revealedCells: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
  },
  revealedMineCells: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.error.main,
  },
  mineCells: {
    backgroundColor: theme.palette.error.main,
  },
}));

const SweeperCell = ({ cellStateParent, revealed, cellClicked }) => {
  const classes = useStyles();

  const initialState = {
    isClicked: false,
    isMine: false,
    posX: 0,
    posY: 0,
    mineCount: 0,
    isFlagged: false,
  };
  const [cellState, setCellState] = useState({
    ...initialState,
    ...cellStateParent,
  });

  useEffect(() => {
    if (cellStateParent) {
      setCellState({ ...cellStateParent });
    }
    return () => {};
  }, [cellStateParent]);

  const clicked = (event) => {
    cellClicked(cellState);
  };

  if (revealed || cellState.isClicked) {
    return (
      <div className={classes.root}>
        {cellState.isMine && <div className={classes.revealedMineCells}>X</div>}
        {!cellState.isMine && (
          <div className={classes.revealedCells}>{cellState.mineCount}</div>
        )}
      </div>
    );
  } else if (cellState.isFlagged) {
    return (
      <div className={classes.root} onClick={clicked}>
        <div className={classes.flaggedCells}></div>
      </div>
    );
  } else {
    return (
      <div className={classes.root} onClick={clicked}>
        {cellState.isMine && <div className={classes.cells}></div>}
        {!cellState.isMine && <div className={classes.cells}></div>}
      </div>
    );
  }
};

export default SweeperCell;
