import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import { green, red } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddTodo from "./AddTodo";
import todoItemService from "../services/todoItemService";
import moment from "moment";
import { Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  fullBody: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  verticalMenu: {
    width: "200px",
    minWidth: "200px",
    marginRight: "5px",
    borderRight: "1px solid",
    borderRightColor: theme.palette.divider,
    backgroundColor: theme.palette.background.default,
  },
  horizontalMenu: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
    borderRightColor: theme.palette.divider,
    backgroundColor: theme.palette.background.default,
  },
  menuItemSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  initialMenuSpace: {
    height: "80px",
  },
  formContainer: {
    width: "100%",
    marginRight: "5px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rootList: {
    width: "100%",
    marginBottom: "30px",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  createTodoContainer: {
    width: "100%",
    margin: "10px 0px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const MenuButton = withStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "left",
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}))(Button);

const CompleteIconButton = withStyles((theme) => ({
  root: {
    color: green[500],
    "&:hover": {
      backgroundColor: "#29612b",
    },
  },
}))(IconButton);

const DeleteIconButton = withStyles((theme) => ({
  root: {
    color: red[500],
    "&:hover": {
      backgroundColor: "#6d3e42",
    },
  },
}))(IconButton);

const todoComponent = function TodoComponent() {
  const classes = useStyles();
  const [allTodos, setAllTodos] = useState([]);
  const [displayInprogress, setDisplayInprogress] = useState(true);
  const [reload, reloadComponent] = useState(0);

  useEffect(() => {
    const fetchTodo = async () => {
      let todos = await todoItemService.getAll();
      if (displayInprogress) {
        todos = todos.filter((todo) => !todo.isCompleted);
      } else {
        todos = todos.filter((todo) => todo.isCompleted);
      }
      setAllTodos(todos);
    };
    fetchTodo();
    return () => {};
  }, [reload, displayInprogress]);

  const createTodo = async ({ title, description }) => {
    await todoItemService.add({
      title,
      description,
    });
    setDisplayInprogress(true);
    refresh();
  };

  const refresh = async () => {
    let val = reload + 1;
    reloadComponent(val);
  };

  const deleteTodo = async (id) => {
    if (!id) return;
    await todoItemService.delete(id);
    refresh();
  };

  const completeTodo = async (todo) => {
    if (!todo) return;
    todo.isCompleted = true;
    await todoItemService.complete(todo);
    refresh();
  };

  return (
    <Grid container component="main" direction="row" className={classes.root}>
      <CssBaseline />
      <Grid
        className={classes.fullBody}
        item
        xs={12}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <div className={classes.fullBody}>
            <Hidden xsDown>
              <div className={classes.verticalMenu}>
                <div className={classes.initialMenuSpace}></div>
                <MenuButton
                  className={displayInprogress ? classes.menuItemSelected : ""}
                  color="primary"
                  onClick={() => {
                    setDisplayInprogress(true);
                    refresh();
                  }}
                >
                  In Progress
                </MenuButton>
                <MenuButton
                  className={!displayInprogress ? classes.menuItemSelected : ""}
                  color="primary"
                  onClick={() => {
                    setDisplayInprogress(false);
                    refresh();
                  }}
                >
                  Completed
                </MenuButton>
              </div>
            </Hidden>

            <div className={classes.formContainer}>
              <div className={classes.headerContainer}>
                <Avatar className={classes.avatar}>
                  <ListAltIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Todo
                </Typography>
              </div>
              <form className={classes.form} noValidate>
                <div className={classes.createTodoContainer}>
                  <AddTodo
                    createTodo={(e) => {
                      createTodo(e);
                    }}
                  ></AddTodo>
                </div>

                <Hidden smUp>
                  <div className={classes.horizontalMenu}>
                    {/* <ButtonGroup> */}
                    <MenuButton
                      className={
                        displayInprogress ? classes.menuItemSelected : ""
                      }
                      onClick={() => {
                        setDisplayInprogress(true);
                        refresh();
                      }}
                    >
                      In Progress
                    </MenuButton>
                    <MenuButton
                      className={
                        !displayInprogress ? classes.menuItemSelected : ""
                      }
                      onClick={() => {
                        setDisplayInprogress(false);
                        refresh();
                      }}
                    >
                      Completed
                    </MenuButton>
                    {/* </ButtonGroup> */}
                  </div>
                </Hidden>

                {allTodos.length > 0 && (
                  <Card className={classes.rootList}>
                    <List>
                      {allTodos.length > 0 &&
                        allTodos.map((todoItem, index) => {
                          return (
                            <div key={todoItem._id}>
                              <ListItem alignItems="flex-start">
                                {!todoItem.isCompleted && (
                                  <ListItemAvatar>
                                    <CompleteIconButton
                                      color="secondary"
                                      edge="end"
                                      aria-label="complete"
                                      onClick={() => {
                                        completeTodo(todoItem);
                                      }}
                                    >
                                      <DoneIcon />
                                    </CompleteIconButton>
                                  </ListItemAvatar>
                                )}

                                <ListItemText
                                  primary={todoItem.title}
                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {todoItem.description}
                                      </Typography>
                                      {` -- ${moment(todoItem.timestamp).format(
                                        "LLL"
                                      )}`}
                                    </React.Fragment>
                                  }
                                />

                                {todoItem.completeDate != null && (
                                  <div>
                                    {`Completed -- 
                                    ${moment(todoItem.completeDate).format(
                                      "LLL"
                                    )}`}
                                  </div>
                                )}

                                <ListItemSecondaryAction>
                                  <div>
                                    <DeleteIconButton
                                      color="primary"
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => {
                                        deleteTodo(todoItem._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </DeleteIconButton>
                                  </div>
                                </ListItemSecondaryAction>
                              </ListItem>
                              {index !== allTodos.length - 1 && (
                                <Divider variant="inset" component="li" />
                              )}
                            </div>
                          );
                        })}
                    </List>
                  </Card>
                )}
              </form>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default todoComponent;
