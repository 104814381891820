import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  CssBaseline,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";

import fitnessService from "../services/fitnessService";

const activities = ["pushups", "situps", "squats"];

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.default,
    flexWrap: "nowrap",
    flexDirection: "column",
  },
  image: {
    height: "350px",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1517344800994-80b20463999c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

const FitnessBoardComponent = () => {
  const classes = useStyles();
  const [squats, setSquats] = useState(0);
  const [pushups, setPushups] = useState(0);
  const [situps, setSitups] = useState(0);

  useEffect(() => {
    const fetchAll = async () => {
      const todaySquat = await fitnessService.getSquatsToday();
      const todayPushup = await fitnessService.getPushUpToday();
      const todaySitup = await fitnessService.getSitupsToday();
      setPushups(todayPushup.totalCount);
      setSquats(todaySquat.totalCount);
      setSitups(todaySitup.totalCount);
    };

    fetchAll();
    return () => {};
  }, []);

  return (
    <Grid
      container
      component="main"
      direction="row"
      className={classes.container}
    >
      <CssBaseline />
      <Grid item className={classes.image} />

      <Grid item xs={12} style={{ padding: 20 }}>
        <Grid container justify="center" spacing={1}>
          {activities.map((value) => (
            <Grid key={value} item>
              <Card variant="outlined">
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Lizard: {squats}
                    </Typography>

                    <Typography gutterBottom variant="h5" component="h2">
                      Lizard: {pushups}
                    </Typography>

                    <Typography gutterBottom variant="h5" component="h2">
                      Lizard: {situps}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FitnessBoardComponent;
