import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExperienceTimeLine from "./ExperienceTimeLine";
import EducationTimeLine from "./EducationTimeLine";
import ProjectTimeLine from "./ProjectTimeLine";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import CallIcon from "@material-ui/icons/Call";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "900px",
    margin: "20px",
  },
  links: {
    backgroundColor: theme.palette.background.default,
    height: 60,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  footerIcons: {
    cursor: "pointer",
    margin: "0px 10px",
  },
  textWrapper: { margin: "0px 30px" },
}));

export const Portfolio = () => {
  const classes = useStyles();
  const openWindow = (url) => {
    window.open(url);
  };
  return (
    <div className={classes.root}>
      <div className={classes.links}>
        <GitHubIcon
          className={classes.footerIcons}
          color="primary"
          onClick={() => openWindow("https://github.com/ronakvpanchal")}
        />
        <LinkedInIcon
          className={classes.footerIcons}
          color="primary"
          onClick={() =>
            openWindow("https://www.linkedin.com/in/ronak-vinod-panchal")
          }
        />
        <EmailIcon
          className={classes.footerIcons}
          color="primary"
          onClick={() => openWindow("mailTo:ronakvpanchal@gmail.com")}
        />
        <TwitterIcon
          className={classes.footerIcons}
          color="primary"
          onClick={() => openWindow("https://twitter.com/_ronak90")}
        />
        <CallIcon
          className={classes.footerIcons}
          color="primary"
          onClick={() => openWindow("tel:+17657149584")}
        />
      </div>
      <div className={classes.textWrapper}>
        <Typography align="left" variant="h6" color="primary" gutterBottom>
          ABOUT
        </Typography>
        <Typography align="left" variant="body1" paragraph={true}>
          Rich industry experience on developing scalable architecture and
          coding patterns using OOD/OOP and SOLID principles. Love to research
          new technologies and trends to apply them in work environments. Have a
          strong will and can-do attitude to deliver excellent products.
        </Typography>
        <Typography align="left" variant="body1" paragraph={true}>
          Strive towards improving code quality and promote rapid development.
          Focus to on user experience flow, design more intuitive and elegant
          apps.
        </Typography>
        <Typography align="left" variant="body1" paragraph={true}>
          Proficient with React.js, Redux, Angular, NodeJS, ASP.NET Core and
          other Web technologies. Hands-on experience with Microsoft Azure,
          Docker and Kubernetes.
        </Typography>
      </div>

      <ExperienceTimeLine></ExperienceTimeLine>
      <ProjectTimeLine></ProjectTimeLine>
      <EducationTimeLine></EducationTimeLine>
    </div>
  );
};

export default Portfolio;
