import { createStore, applyMiddleware, compose } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from "redux-thunk";
import rootReducer from "./reducer";

const isDev = true;
const composeEnhancers =
  isDev && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const persistConfig = { key: 'batmanIsRonak', storage };
const persistedReducer = persistReducer(persistConfig, rootReducer)
const middlewares = [thunk];
const enhancers = composeEnhancers(applyMiddleware(...middlewares));

const initialState = {};

let store = createStore(persistedReducer, initialState, enhancers)
let persistor = persistStore(store)

export default { store, persistor }
