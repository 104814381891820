import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Card, CardContent } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import fitnessService from "../../services/fitnessService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  fullBody: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
    minHeight: "60px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardsContainer: {
    overflow: "auto",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  addContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 10px",
    padding: "20px",
  },
  listContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 10px",
    padding: "20px",
  },
  pushupDetail: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  counterText: {
    width: "200px",
  },
  addButton: {
    marginTop: "20px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PushUpComponent = function () {
  const classes = useStyles();
  const [allPushups, setAllPushups] = useState(null);
  const [pushupCount, setPushupCount] = useState(0);
  const [totalPushupToday, setTotalPushupToday] = useState(0);
  const [reloadCount, refresh] = useState(0);

  useEffect(() => {
    const fetchPushups = async () => {
      const result = await fitnessService.getPushUpToday();
      setAllPushups(result.activities);
      setTotalPushupToday(result.totalCount);
    };
    fetchPushups();
    return () => {};
  }, [reloadCount]);

  const addPushUp = async () => {
    await fitnessService.addPushups(pushupCount);
    setPushupCount(0);
    refresh();
  };

  return (
    <Grid container component="main" direction="row" className={classes.root}>
      <CssBaseline />
      <Grid
        className={classes.fullBody}
        item
        xs={12}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <div className={classes.headerContainer}>
            <Avatar className={classes.avatar}>
              <FitnessCenterIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Push Ups Tracking
            </Typography>
          </div>
          <div className={classes.cardsContainer}>
            <Card className={classes.addContainer}>
              <div className={classes.formContainer}>
                <CardContent>
                  <form noValidate className={classes.form}>
                    <TextField
                      className={classes.counterText}
                      label="Number"
                      type="number"
                      value={pushupCount}
                      onChange={(e) => {
                        setPushupCount(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />

                    <Button
                      className={classes.addButton}
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxIcon />}
                      onClick={addPushUp}
                    >
                      Add
                    </Button>
                  </form>
                </CardContent>
              </div>
            </Card>
            <Card className={classes.listContainer}>
              <CardContent className={classes.pushupDetail}>
                <Typography variant="subtitle1" color="textSecondary">
                  Today's Total PushUp
                </Typography>

                <Typography variant="h3" component="h5" color="primary">
                  {`${totalPushupToday} Counts`}
                </Typography>
              </CardContent>
            </Card>
            {allPushups != null &&
              allPushups.length > 0 &&
              allPushups.map((pushup) => {
                return (
                  <Card key={pushup._id} className={classes.listContainer}>
                    <CardContent className={classes.pushupDetail}>
                      <Typography variant="h4" component="h5" color="primary">
                        {`${pushup.count} Counts`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {` Date: 
                      ${moment(pushup.completedUtcTimestamp).format("LL")}`}
                      </Typography>

                      <Typography variant="subtitle1" color="textSecondary">
                        {` Time: 
                      ${moment(pushup.completedUtcTimestamp).format("LTS")}`}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PushUpComponent;
