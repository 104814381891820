import axios from "axios";

export default {
  login: async (userParams) => {
    let res = await axios.post(`/api/auth`, userParams);
    return res.data || {};
  },

  logout: async () => {
    let res = await axios.post(`/api/logout`, {});
    return res.data || {};
  }
};
