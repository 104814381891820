import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../reducers/user";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  fullBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  image: {
    height: 0,
    paddingTop: "40%",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1554579653-17cdf3bfbca6?ixlib=rb-1.2.1&auto=format&fit=crop&q=80&w=900)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  defaultBackground: {
    backgroundColor: theme.palette.background.default,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const signIn = function SignInSide() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmailValue] = useState("user@mail.com");
  const [username, setUsername] = useState("");
  const [isSignUpFlow, setSignUpFlow] = useState(false);
  const [password, setPasswordValue] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.loggedIn) {
      // go to user dashboard
      history.push("/");
    }
    return () => {};
  }, [user]);

  const signUpUser = async () => {
    await dispatch(loginUser({ username, email, password }));
  };

  const signInUser = async () => {
    await dispatch(loginUser({ email, password }));
  };

  return (
    <Grid container component="main" direction="row" className={classes.root}>
      <CssBaseline />
      <Grid
        className={classes.fullBody}
        item
        xs={12}
        component={Paper}
        elevation={2}
        square
      >
        <Grid item xs={12} className={classes.image} />
        <Grid
          className={classes.defaultBackground}
          item
          xs={12}
          component={Paper}
          elevation={2}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              {isSignUpFlow && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  style={{ minWidth: "300px", maxWidth: "500px" }}
                  id="name"
                  label="Username"
                  name="username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  autoComplete="name"
                  autoFocus
                />
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ minWidth: "300px", maxWidth: "500px" }}
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={(event) => setEmailValue(event.target.value)}
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ minWidth: "300px", maxWidth: "500px" }}
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPasswordValue(event.target.value)}
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              {!isSignUpFlow && (
                <Button
                  style={{ minWidth: "300px", maxWidth: "500px" }}
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={signInUser}
                >
                  Sign In
                </Button>
              )}

              {isSignUpFlow && (
                <Button
                  style={{ minWidth: "300px", maxWidth: "500px" }}
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={signUpUser}
                >
                  Sign Up
                </Button>
              )}
              <Grid container>
                <Grid item xs>
                  {!isSignUpFlow && (
                    <Link
                      href="#"
                      rel="noopener"
                      color="secondary"
                      variant="body2"
                      onClick={() => {
                        setSignUpFlow(true);
                      }}
                    >
                      New User? Sign Up Here
                    </Link>
                  )}

                  {isSignUpFlow && (
                    <Link
                      href="#"
                      rel="noopener"
                      color="secondary"
                      variant="body2"
                      onClick={() => {
                        setSignUpFlow(false);
                      }}
                    >
                      Log In User
                    </Link>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default signIn;
