import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import CallIcon from "@material-ui/icons/Call";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: 60,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  footerIcons: {
    cursor: "pointer",
    margin: "0px 10px",
  },
}));

function Copyright() {
  const classes = useStyles();

  const openWindow = (url) => {
    window.open(url);
  };

  return (
    <div className={classes.root}>
      <GitHubIcon
        className={classes.footerIcons}
        color="primary"
        onClick={() => openWindow("https://github.com/ronakvpanchal")}
      />
      <LinkedInIcon
        className={classes.footerIcons}
        color="primary"
        onClick={() =>
          openWindow("https://www.linkedin.com/in/ronak-vinod-panchal")
        }
      />
      <EmailIcon
        className={classes.footerIcons}
        color="primary"
        onClick={() => openWindow("mailTo:ronakvpanchal@gmail.com")}
      />
      <TwitterIcon
        className={classes.footerIcons}
        color="primary"
        onClick={() => openWindow("https://twitter.com/_ronak90")}
      />
      <CallIcon
        className={classes.footerIcons}
        color="primary"
        onClick={() => openWindow("tel:+17657149584")}
      />
    </div>
  );
}

export default Copyright;
