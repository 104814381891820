import { combineReducers } from "redux";
import { userReducer } from "./reducers/user";
import { hackerNewsReducer } from "./reducers/hackerNews";

const appReducers = combineReducers({
  router: {},
  user: userReducer,
  hackerNews: hackerNewsReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
