import axios from "axios";

const TodoItemService = {
  add: async ({ title, description }) => {
    const todoItem = {
      isCompleted: false,
      title: title,
      description: description,
    };
    let res = await axios.post(`/api/todoItem`, todoItem);
    return res.data || {};
  },

  getAll: async () => {
    let res = await axios.get(`/api/todoItem`);
    return res.data || {};
  },

  put: async (todoItem) => {
    let res = await axios.put(`/api/todoItem`, todoItem);
    return res.data || {};
  },

  complete: async (todoItem) => {
    let res = await axios.put(`/api/todoItemComplete`, todoItem);
    return res.data || {};
  },

  delete: async (id) => {
    let res = await axios.delete(`/api/todoItem`, {
      data: { _id: id },
    });
    return res.data || {};
  },
};

export default TodoItemService;
