import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemIcon } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SchoolIcon from "@material-ui/icons/School";
import LensIcon from "@material-ui/icons/Lens";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import { useWindowResize } from "../utils/windowResizeHook";

const useStyles = makeStyles((theme) => ({
  root: { textAlign: "left" },
  durationText: {
    marginTop: "8px",
    marginBottom: "5px",
    fontStyle: "italic",
  },
  positionText: {
    marginBottom: "5px",
  },
  listItem: {
    padding: "0px",
  },
  listIcon: {
    minWidth: "30px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeLineContainer: {
    flex: "3",
  },
  oppositeContent: {
    maxWidth: "160px",
    minWidth: 160,
  },
  missingOppositeContentOverride: {
    "&:before": {
      flex: 0,
      padding: 10,
    },
  },
}));

export default function ExperienceTimeLineList({ listItems }) {
  const classes = useStyles();
  const denseList = true;
  const { width } = useWindowResize();
  const [showOppositeContent, setshowOppositeContentOption] = useState(true);

  useEffect(() => {
    if (width <= 750) {
      setshowOppositeContentOption(false);
    } else {
      setshowOppositeContentOption(true);
    }
    return () => {
      return null;
    };
  }, [width]);

  return (
    <div className={classes.root}>
      {listItems.map((item, itemId) => {
        return (
          <TimelineItem
            key={itemId}
            classes={{
              missingOppositeContent: classes.missingOppositeContentOverride,
            }}
          >
            {showOppositeContent && (
              <TimelineOppositeContent className={classes.oppositeContent}>
                <Typography
                  className={classes.durationText}
                  variant="body1"
                  color="primary"
                >
                  {item.rightContent}
                </Typography>
              </TimelineOppositeContent>
            )}
            <TimelineSeparator>
              <TimelineDot color="primary">
                {item.icon === "laptop" && <LaptopMacIcon />}
                {item.icon === "school" && <SchoolIcon />}
                {item.icon === "project" && <EmojiObjectsIcon />}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timeLineContainer}>
              <div>
                <Typography variant="h6">{item.title}</Typography>
                <Typography
                  className={classes.positionText}
                  variant="subtitle2"
                >
                  {item.caption}
                </Typography>
                {!showOppositeContent && (
                  <Typography
                    className={classes.durationText}
                    variant="body1"
                    color="primary"
                    align="right"
                  >
                    {item.rightContent}
                  </Typography>
                )}
                {item.summary != null && item.summary.length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel-content"
                      id={itemId}
                    >
                      <Typography variant="subtitle2">Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List dense={denseList}>
                        {item.summary.map((summary, summaryId) => {
                          return (
                            <ListItem
                              key={summaryId}
                              className={classes.listItem}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <ChevronRightIcon />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{
                                  variant: "caption",
                                }}
                                primary={summary.description}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            </TimelineContent>
          </TimelineItem>
        );
      })}
      <TimelineItem
        classes={{
          missingOppositeContent: classes.missingOppositeContentOverride,
        }}
      >
        {showOppositeContent && (
          <TimelineOppositeContent className={classes.oppositeContent}>
            <Typography
              className={classes.durationText}
              variant="body1"
              color="textSecondary"
            ></Typography>
          </TimelineOppositeContent>
        )}
        <TimelineSeparator>
          <TimelineDot>
            <LensIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent className={classes.timeLineContainer}>
          <Typography variant="h6"></Typography>
        </TimelineContent>
      </TimelineItem>
    </div>
  );
}
