import axios from "axios";
import moment from "moment";

const FitnessService = {
  addPushups: async (count) => {
    const pushUp = {
      count: count,
      activityType: "pushUps",
    };
    let res = await axios.post(`/api/fitness`, pushUp);
    return res.data || {};
  },

  addSquats: async (count) => {
    const squats = {
      count: count,
      activityType: "squats",
    };
    let res = await axios.post(`/api/fitness`, squats);
    return res.data || {};
  },

  addSitups: async (count) => {
    const situps = {
      count: count,
      activityType: "situps",
    };
    let res = await axios.post(`/api/fitness`, situps);
    return res.data || {};
  },

  getAll: async () => {
    let res = await axios.get(`/api/fitness_pushup`);
    return res.data || {};
  },

  getPushUpToday: async () => {
    const timestamp = moment().startOf("day").valueOf();
    let res = await axios.get(
      `/api/fitness_today?timestamp=${timestamp}&activityType=pushUps`
    );
    return res.data || {};
  },

  getSquatsToday: async () => {
    const timestamp = moment().startOf("day").valueOf();
    let res = await axios.get(
      `/api/fitness_today?timestamp=${timestamp}&activityType=squats`
    );
    return res.data || {};
  },

  getSitupsToday: async () => {
    const timestamp = moment().startOf("day").valueOf();
    let res = await axios.get(
      `/api/fitness_today?timestamp=${timestamp}&activityType=situps`
    );
    return res.data || {};
  },
};

export default FitnessService;
