import * as _ from "lodash";
import hackerNewsService from "../services/hackerNewsService";

const FETCH_STORIES = "FETCH_STORIES";

const initialState = {
  allStories: [],
};

export const fetchStories = (storyIds) => {
  return async (dispatch, getState) => {
    let requestApis = [];
    const { hackerNews } = getState();
    const maxCount = 25 <= storyIds.length ? 25 : storyIds.length;

    for (let i = 0; i < maxCount; i++) {
      const exists = _.find(hackerNews.allStories, (story) => {
        return story.id === storyIds[i];
      });

      if (!exists) {
        const fn = await hackerNewsService.getStory(storyIds[i]);
        requestApis.push(fn);
      }
    }

    const allStoriesInState = [...hackerNews.allStories];
    _.each(requestApis, (item) => {
      allStoriesInState.push(item);
    });

    await dispatch({
      type: FETCH_STORIES,
      payload: { allStories: [...allStoriesInState] },
    });
  };
};

export const hackerNewsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STORIES:
      return { ...payload };
    default:
      return state;
  }
};
