import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // "& > * + *": {
    //   marginTop: theme.spacing(2),
    // },
  },
}));

export default function Notifications({
  type = "info",
  msg,
  onClose,
  duration = false,
}) {
  // type = error, warning, info, success
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  if (duration) {
    setTimeout(() => {
      closeNotification();
    }, 3000);
  }

  const closeNotification = () => {
    setOpen(false);
    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  return (
    <Collapse in={open} className={classes.root}>
      <Alert
        variant="filled"
        severity={type}
        onClose={closeNotification}
        // action={
        //     <Button color="inherit" size="small">
        //       UNDO
        //     </Button>
        //   }
      >
        {msg}
      </Alert>
    </Collapse>
  );
}
