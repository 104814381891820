import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircle from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  addIcon: {
    marginRight: "5px",
    color: theme.palette.secondary.main,
  },
  secondaryHeading: {
    display: "flex",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function DetailedAccordion({ createTodo }) {
  const classes = useStyles();
  const [todoItemTitle, setTodoItemTitle] = useState("");
  const [todoItemDescription, setTodoItemDescription] = useState("");
  const [expanded, setExpanded] = useState(false);

  const addTodo = async () => {
    createTodo({ title: todoItemTitle, description: todoItemDescription });
    setTodoItemTitle("");
    setTodoItemDescription("");
    setExpanded(false);
  };

  const toggleExpand = async () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={toggleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <div className={classes.secondaryHeading}>
              <div className={classes.addIcon}>
                <AddCircle />
              </div>
              Add a New Todo
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <TextField
            style={{ width: "100%", marginBottom: "20px" }}
            label="Add Title"
            variant="outlined"
            value={todoItemTitle}
            onChange={(event) => setTodoItemTitle(event.target.value)}
          />
          <TextField
            style={{ width: "100%" }}
            label="Add Todo"
            variant="outlined"
            multiline
            rows={3}
            rowsMax={5}
            value={todoItemDescription}
            onChange={(event) => setTodoItemDescription(event.target.value)}
          />
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            size="small"
            onClick={() => {
              setExpanded(false);
            }}
          >
            Cancel
          </Button>
          <Button color="secondary" size="small" onClick={addTodo}>
            Add
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
}
