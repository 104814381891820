import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import Typography from "@material-ui/core/Typography";
import { EDUCATION } from "./allWorkItems";
import PortfolioTimeLineList from "./PortfolioTimeLineList";

const useStyles = makeStyles((theme) => ({
  root: { textAlign: "left" },
  textWrapper: { margin: "0px 30px" },
}));

export default function ExperienceTimeLine() {
  const classes = useStyles();
  const listItems = EDUCATION;

  return (
    <div className="classes.root">
      <div className={classes.textWrapper}>
        <Typography align="left" variant="h6" color="primary" gutterBottom>
          Education
        </Typography>
      </div>
      <Timeline align="left">
        <PortfolioTimeLineList listItems={listItems}></PortfolioTimeLineList>
      </Timeline>
    </div>
  );
}
