import loginService from "../services/loginService";

const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";

const initialState = {
  loggedIn: false,
};

export const loginUser = (user) => {
  return async (dispatch) => {
    const userLoggedIn = await loginService.login(user);
    if (userLoggedIn.login === "userLoggedIn") {
      await dispatch({
        type: LOGIN_USER,
        payload: { ...userLoggedIn, loggedIn: true },
      });
    }
  };
};

export const logoutUser = (user) => {
  return async (dispatch) => {
    const logout = await loginService.logout();
    if (logout.success) {
      await dispatch({ type: LOGOUT_USER });
    }
  };
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_USER:
      return { ...state, ...payload };
    case LOGOUT_USER:
      return { loggedIn: false };
    default:
      return state;
  }
};
