import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import hackerNewsService from "../services/hackerNewsService";
import { HackerNewsCardContainer } from "./hackerNewsCardContainer";
import * as _ from "lodash";
import { fetchStories } from "../reducers/hackerNews";
import { connect } from "react-redux";

const styles = (theme) => ({
  rootContainer: {
    backgroundColor: theme.palette.background.default,
    height: "65px",
    color: "white",
    fontSize: "24px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hackerContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    overflow: "auto",
  },
});

class HackerNewsScreen extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    value: 0,
    displayedStories: [],
    allStories: [],
  };

  tabChanged = (event, value) => {
    this.setState({ value: value, displayedStories: [] }, this.fetchTabData);
  };

  fetchTabData = async () => {
    const value = this.state.value;
    if (value === 0) {
      this.fetchTopStories();
    } else if (value === 1) {
      this.fetchNewStories();
    } else if (value === 2) {
      this.fetchBestStories();
    }
  };

  async componentDidMount() {
    await this.fetchTabData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.allStories != null &&
      this.props.allStories != null &&
      prevProps.allStories.length !== this.props.allStories.length
    ) {
      // this.setDisplayedStories();
    }
  }

  fetchTopStories = async () => {
    const topStories = await hackerNewsService.getTopStories();
    this.setState({ topStoryIds: topStories }, () => {
      this.fetchStories(topStories);
    });
  };

  fetchBestStories = async () => {
    const bestStories = await hackerNewsService.getBestStories();
    this.setState({ bestStoryIds: bestStories }, () => {
      this.fetchStories(bestStories);
    });
  };

  fetchNewStories = async () => {
    const newStories = await hackerNewsService.getNewStories();
    this.setState({ newStoryIds: newStories }, () => {
      this.fetchStories(newStories);
    });
  };

  fetchStories = async (storyIds) => {
    await this.props.fetchStoriesAction(storyIds);
    this.setDisplayedStories();
  };

  setDisplayedStories = async () => {
    let storyIds = [];

    if (this.state.value === 0) {
      storyIds = this.state.topStoryIds;
    } else if (this.state.value === 1) {
      storyIds = this.state.newStoryIds;
    } else if (this.state.value === 2) {
      storyIds = this.state.bestStoryIds;
    }

    let storiesToDisplay = [];

    _.each(storyIds, (id) => {
      const exists = _.find(this.props.allStories, (story) => {
        return story.id === id;
      });

      if (exists) {
        storiesToDisplay.push(exists);
      }
    });

    this.setState({ displayedStories: storiesToDisplay });
  };

  fetchStory = async (id) => {
    return hackerNewsService.getStory(id);
  };

  openStoryLink = (story) => {
    window.open(story.url);
  };

  render() {
    const { classes } = this.props;
    const { value, displayedStories } = this.state;

    return (
      <div className={classes.hackerContainer}>
        <div className={classes.rootContainer}>Hacker News</div>

        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs value={value} onChange={this.tabChanged}>
              <Tab label="Top Stories" />
              <Tab label="New Stories" />
              <Tab label="Best Stories" />
            </Tabs>
          </AppBar>
          {value === 0 && displayedStories.length > 0 && (
            <HackerNewsCardContainer
              stories={displayedStories}
            ></HackerNewsCardContainer>
          )}
          {value === 1 && displayedStories.length > 0 && (
            <HackerNewsCardContainer
              stories={displayedStories}
            ></HackerNewsCardContainer>
          )}
          {value === 2 && displayedStories.length > 0 && (
            <HackerNewsCardContainer
              stories={displayedStories}
            ></HackerNewsCardContainer>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { hackerNews } = state;
  return { allStories: hackerNews.allStories };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStoriesAction: (e) => dispatch(fetchStories(e)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HackerNewsScreen));
