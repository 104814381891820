import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HackerNewsCard } from "./HackerNewsCard";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { HackerNewsActionBar } from "./HackerNewsActionBar";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(18),
      marginRight: theme.spacing(18),
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(20),
    },
  },
}));

export function HackerNewsCardContainer({ stories }) {
  const [cardStories, setCardStories] = useState(stories);

  const classes = useStyles();

  const searchOptionChanged = (searchValue) => {
    if (searchValue === "") {
      setCardStories(stories);
      return;
    }

    const filteredCardStories = _.filter(cardStories, (story) => {
      const searchString = story.title.toLowerCase();
      const filter = searchString.indexOf(searchValue);

      return filter > -1;
    });
    setCardStories(filteredCardStories);
  };

  const sortOptionChanged = (sortValue) => {
    if (sortValue === "score") {
      sortByScore();
    } else if (sortValue === "date") {
      sortByDate();
    } else {
      const sortedArray = _.cloneDeep(stories);
      setCardStories(sortedArray);
    }
  };

  const sortByScore = () => {
    const sortedArray = _.sortBy(stories, ["score"]);
    setCardStories(sortedArray.reverse());
  };

  const sortByDate = () => {
    const sortedArray = _.sortBy(stories, ["time"]);
    setCardStories(sortedArray.reverse());
  };

  return (
    <div>
      <div>
        <HackerNewsActionBar
          sortOptionChanged={sortOptionChanged}
          searchOptionChanged={searchOptionChanged}
        ></HackerNewsActionBar>
      </div>
      <Typography variant="subtitle1" color="textSecondary">
        Displayed Stories: {cardStories.length}
      </Typography>
      <div></div>
      <div className={classes.cardContainer}>
        {cardStories &&
          cardStories.length &&
          cardStories.map((story, index) => {
            return <HackerNewsCard key={index} story={story}></HackerNewsCard>;
          })}
      </div>
    </div>
  );
}

HackerNewsCardContainer.propTypes = {
  stories: PropTypes.array,
};
